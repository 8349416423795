:root {
  --plyr-video-object-fit: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  color: white;
  background-color: black;
}

.rotate-90 {
  width: 100vh !important;
  height: 100vw !important;
  transform: rotate(0deg) !important;
}

.rotate-270 {
  width: 100vh !important;
  height: 100vw !important;
  transform: rotate(360deg) !important;
}
